<template lang="">
  <div
    v-if="bankSellingRate"
    id="bankSellingRateInfo"
    class="d-flex-center gap-1 mx-50 font-weight-bold text-white"
  >
    <span
      v-show="!isMobileView"
      class="text-nowrap"
    >{{ $t('bankSellingRate') }} $:</span>
    <span v-if="loading">
      <feather-icon
        icon="LoaderIcon"
        size="16"
        class="spinner mx-50"
      />
    </span>
    <span
      v-else
      class="cursor-pointer"
      @click="fetchBankSellingRate"
    >
      {{ formatCurrency(bankSellingRate || 0) }}
    </span>
    <b-tooltip
      v-if="!isMobileView"
      id="tooltip-banking-rate-info"
      target="bankSellingRateInfo"
      triggers="hover"
      placement="bottom"
      boundary="window"
      variant="light"
      container="div"
      custom-class="border-dark rounded"
    >
      <div
        class="font-small-4 d-flex flex-column align-items-start"
        @click="hideTooltip"
      >
        <div>{{ $t('bankSellingRate') }} 1G </div>
        <div
          v-if="timeFetchToShow"
          class="text-nowrap"
        >
          {{ $t('latestUpdate') }}: {{ timeFetchToShow }}
        </div>
      </div>
    </b-tooltip>
  </div>
</template>
<script>
import { onMounted, ref, computed } from '@vue/composition-api'
import { BTooltip } from 'bootstrap-vue'

import { apiGeneral } from '@/api'

import { formatCurrency, convertISODateTime } from '@core/utils/filter'

export default {
  components: { BTooltip },
  setup() {
    // for check bankSellingRate
    const bankSellingRate = ref()
    const timeFetch = ref()
    const loading = ref(false)
    async function fetchBankSellingRate() {
      loading.value = true
      const payload = {
        from: 'USD',
        to: 'VND',
        amount: 1,
      }
      try {
        const response = await apiGeneral.currencyConversion(payload)
        timeFetch.value = new Date()
        bankSellingRate.value = response?.bankSellingRate || null
      } catch (error) {
        console.error(error)
      }
      loading.value = false
    }
    onMounted(() => {
      fetchBankSellingRate()
    })

    const timeFetchToShow = computed(() => convertISODateTime(timeFetch.value).dateTime)
    function hideTooltip() {
      this.$root.$emit('bv::hide::tooltip')
    }
    return {
      formatCurrency,
      fetchBankSellingRate,
      convertISODateTime,
      hideTooltip,
      bankSellingRate,
      timeFetchToShow,
      loading,
    }
  },
}
</script>
<style lang="scss">
#tooltip-banking-rate-info {
  .tooltip-inner {
    max-width: unset;
  }
}
</style>
