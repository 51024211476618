var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.bankSellingRate ? _c('div', {
    staticClass: "d-flex-center gap-1 mx-50 font-weight-bold text-white",
    attrs: {
      "id": "bankSellingRateInfo"
    }
  }, [_c('span', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.isMobileView,
      expression: "!isMobileView"
    }],
    staticClass: "text-nowrap"
  }, [_vm._v(_vm._s(_vm.$t('bankSellingRate')) + " $:")]), _vm.loading ? _c('span', [_c('feather-icon', {
    staticClass: "spinner mx-50",
    attrs: {
      "icon": "LoaderIcon",
      "size": "16"
    }
  })], 1) : _c('span', {
    staticClass: "cursor-pointer",
    on: {
      "click": _vm.fetchBankSellingRate
    }
  }, [_vm._v(" " + _vm._s(_vm.formatCurrency(_vm.bankSellingRate || 0)) + " ")]), !_vm.isMobileView ? _c('b-tooltip', {
    attrs: {
      "id": "tooltip-banking-rate-info",
      "target": "bankSellingRateInfo",
      "triggers": "hover",
      "placement": "bottom",
      "boundary": "window",
      "variant": "light",
      "container": "div",
      "custom-class": "border-dark rounded"
    }
  }, [_c('div', {
    staticClass: "font-small-4 d-flex flex-column align-items-start",
    on: {
      "click": _vm.hideTooltip
    }
  }, [_c('div', [_vm._v(_vm._s(_vm.$t('bankSellingRate')) + " 1G ")]), _vm.timeFetchToShow ? _c('div', {
    staticClass: "text-nowrap"
  }, [_vm._v(" " + _vm._s(_vm.$t('latestUpdate')) + ": " + _vm._s(_vm.timeFetchToShow) + " ")]) : _vm._e()])]) : _vm._e()], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }